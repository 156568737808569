import React from "react";
import { useTranslation } from "react-i18next";
import { CustomHelmet } from "../components/customhelmet";

export function AboutUs() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <CustomHelmet title={t("helmet.aboutus.title")}
                description={t("helmet.aboutus.description")}
                keywords={t("helmet.aboutus.keywords")}
                ogTitle={t("helmet.aboutus.title")}
                ogDescription={t("helmet.aboutus.description")}
                ogImage={t("url.helmetImage")}
            />
            <h1 className="cc-h1">{t("pages.aboutus.title")}</h1>
            <div className="container text-start">
                <p>{t("pages.aboutus.p1")}</p>
                <p>{t("pages.aboutus.p2")}</p>
                <p>{t("pages.aboutus.p3")}</p>
                <h3>{t("pages.aboutus.h1")}</h3>
                <span><a href="https://relre.dev" target="_blank">relre</a> & <a href="https://github.com/oguzkk" target="_blank">OguzKK</a> </span>
            </div>
        </React.Fragment>);
}