
export const difficulty = {
    1: 35,
    2: 30,
    3: 25,
    4: 21,
    5: 17,
};

export const maxErrorCount = 3;

export const initialBoard = "123456789456789123789123456231564897564897231897231564312645978645978312978312645";
export const initialEmptyBoard = "000000000000000000000000000000000000000000000000000000000000000000000000000000000";
