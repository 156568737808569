import React from "react";
import { useTranslation } from "react-i18next";
import { CustomHelmet } from "../components/customhelmet";

export function Privacy() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <CustomHelmet title={t("helmet.privacy.title")}
                description={t("helmet.privacy.description")}
                keywords={t("helmet.privacy.keywords")}
                ogTitle={t("helmet.privacy.title")}
                ogDescription={t("helmet.privacy.description")}
                ogImage={t("url.helmetImage")}
            />
            <h1 className="cc-h1">{t("pages.privacy.title")}</h1>
            <div className="container text-start">
                <p>{t("pages.privacy.p1")}</p>
                <p>{t("pages.privacy.p2")}</p>
                <p>{t("pages.privacy.p3")}</p>
                <h2>{t("pages.privacy.h1")}</h2>
                <p>{t("pages.privacy.p4")}</p>
                <h2>{t("pages.privacy.h2")}</h2>
                <p>{t("pages.privacy.p5")}</p>
                <h2>{t("pages.privacy.h3")}</h2>
                <p>{t("pages.privacy.p6")}</p>
                <h2>{t("pages.privacy.h4")}</h2>
                <p>{t("pages.privacy.p7")}</p>
                <ol type="a">
                    <li>{t("pages.privacy.p8")}</li>
                    <li>{t("pages.privacy.p9")}</li>
                    <li>{t("pages.privacy.p10")}</li>
                    <li>{t("pages.privacy.p11")}</li>
                    <li>{t("pages.privacy.p12")}</li>
                    <li>{t("pages.privacy.p13")}</li>
                    <li>{t("pages.privacy.p14")}</li>
                    <li>{t("pages.privacy.p15")}</li>
                    <li>{t("pages.privacy.p16")}</li>
                </ol>
                <p>{t("pages.privacy.p17")}</p>
                <h2>{t("pages.privacy.h5")}</h2>
                <p>{t("pages.privacy.p18")}</p>
            </div>
        </React.Fragment>);
}