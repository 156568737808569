import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from "./locales/en/translation.json";
import translationTR from "./locales/tr/translation.json";

import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
    en: {
        translation: translationEN
    },
    tr: {
        translation: translationTR
    }
};


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        lng: 'en', // default language
        debug: true,
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;