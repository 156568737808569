import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HomePage } from "./homepage";

export function HomePageEn() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        changeLanguage("en");
    }, [])


    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };
    return (
        <React.Fragment>
            <HomePage></HomePage>
        </React.Fragment>);
}