import React from "react";
import { useTranslation } from "react-i18next";
import { CustomHelmet } from "../components/customhelmet";

export function HowTo() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <CustomHelmet title={t("helmet.howto.title")}
                description={t("helmet.howto.description")}
                keywords={t("helmet.howto.keywords")}
                ogTitle={t("helmet.howto.title")}
                ogDescription={t("helmet.howto.description")}
                ogImage={t("url.helmetImage")}
            />
            <h1 className="cc-h1">{t("pages.howto.title")}</h1>
            <div className="container text-start">
                <p>{t("pages.howto.p1")}</p>
                <h2 className="mb-4">{t("pages.howto.header1")}</h2>
                <p><strong>{t("pages.howto.p2")}</strong>{t("pages.howto.p3")}</p>
                <p><strong>{t("pages.howto.p4")}</strong>{t("pages.howto.p5")}</p>
                <p><strong>{t("pages.howto.p6")}</strong>{t("pages.howto.p7")}</p>
                <p><strong>{t("pages.howto.p8")}</strong>{t("pages.howto.p9")}</p>
                <p><strong>{t("pages.howto.p10")}</strong>{t("pages.howto.p11")}</p>
                <p><strong>{t("pages.howto.p12")}</strong>{t("pages.howto.p13")}</p>
                <p>{t("pages.howto.p14")}</p>
            </div>
        </React.Fragment>);
}