import React from "react";
import { useTranslation } from "react-i18next";
import { CustomHelmet } from "../components/customhelmet";

export function Rules() {
   const { t } = useTranslation();
   return (
      <React.Fragment>
         <CustomHelmet title={t("helmet.sudokurules.title")}
            description={t("helmet.sudokurules.description")}
            keywords={t("helmet.sudokurules.keywords")}
            ogTitle={t("helmet.sudokurules.title")}
            ogDescription={t("helmet.sudokurules.description")}
            ogImage={t("url.helmetImage")}
         />
         <h1 className="cc-h1">{t("pages.rules.title")}</h1>
         <div className="container text-start">
            <p><strong>{t("pages.rules.p2")}</strong>{t("pages.rules.p3")}</p>
            <p><strong>{t("pages.rules.p4")}</strong>{t("pages.rules.p5")}</p>
            <p><strong>{t("pages.rules.p6")}</strong>{t("pages.rules.p7")}</p>
            <p><strong>{t("pages.rules.p8")}</strong>{t("pages.rules.p9")}</p>
            <p><strong>{t("pages.rules.p10")}</strong>{t("pages.rules.p11")}</p>
         </div>
      </React.Fragment>);
}