import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "../constants/colors";
import { Sudoku } from "../helpers/sudoku";
import { ISudokuCell } from "../types/interfaces";
import { CustomHelmet } from "../components/customhelmet";

export function SudokuSolver() {
    const { t } = useTranslation();


    const canvasRef = useRef<HTMLCanvasElement>(null);
    const gridSize = useRef<number>(9);
    const selectedX = useRef<number>(0);
    const selectedY = useRef<number>(0);
    const isSelected = useRef<boolean>(false);
    const initialized = useRef<boolean>(false);
    const sudoku = useRef<Sudoku>(new Sudoku());

    const clearGrid = () => {
        const ctx = canvasRef.current!.getContext('2d')!;
        ctx.clearRect(0, 0, 1000, 1000);
    }
    const drawGrid = () => {
        const ctx = canvasRef.current!.getContext('2d')!;
        if (isSelected.current) {
            const selectedVal = sudoku
                .current
                .board[selectedY.current][selectedX.current]
                .Value;
            const smallGridX = Math.floor(selectedX.current / Math.sqrt(gridSize.current));
            const smallGridY = Math.floor(selectedY.current / Math.sqrt(gridSize.current));
            for (let x = 0; x < gridSize.current; x++) {
                for (let y = 0; y < gridSize.current; y++) {
                    if (x == selectedX.current || y == selectedY.current || (smallGridX == Math.floor(x / Math.sqrt(gridSize.current)) && smallGridY == Math.floor(y / Math.sqrt(gridSize.current)))) {
                        ctx.fillStyle = Colors.secondarySelection;
                        ctx.fillRect(x * (1000 / gridSize.current), y * (1000 / gridSize.current), 1000 / gridSize.current, 1000 / gridSize.current);
                    }
                    if (selectedVal != "0" && selectedVal == sudoku
                        .current
                        .board[y][x]
                        .Value) {
                        ctx.fillStyle = "#C3D7EA";
                        ctx.fillRect(x * (1000 / gridSize.current), y * (1000 / gridSize.current), 1000 / gridSize.current, 1000 / gridSize.current);
                    }
                }
            }
            ctx.fillStyle = Colors.primarySelection;
            ctx.fillRect(selectedX.current * (1000 / gridSize.current), selectedY.current * (1000 / gridSize.current), 1000 / gridSize.current, 1000 / gridSize.current);
        }
        ctx.strokeStyle = Colors.primaryLine;
        ctx.lineWidth = 4;
        ctx.strokeRect(3, 3, 994, 994);
        for (let index = 0; index < gridSize.current + 1; index++) {
            ctx.beginPath();
            ctx.strokeStyle = Colors.primaryLine;
            ctx.lineWidth = index % Math.sqrt(gridSize.current) == 0
                ? 5
                : 1;
            ctx.moveTo(0, (index / gridSize.current) * 1000);
            ctx.lineTo(1000, (index / gridSize.current) * 1000);
            ctx.stroke();
            ctx.beginPath();
            ctx.strokeStyle = Colors.primaryLine;
            ctx.lineWidth = index % Math.sqrt(gridSize.current) == 0
                ? 5
                : 1;
            ctx.moveTo((index / gridSize.current) * 1000, 0);
            ctx.lineTo((index / gridSize.current) * 1000, 1000);
            ctx.stroke();
        }
        for (let x = 0; x < gridSize.current; x++) {
            for (let y = 0; y < gridSize.current; y++) {
                const cell = sudoku.current.board[x][y];
                if (cell.Value != "0") {
                    ctx.font = "70pt Arial";
                    ctx.fillStyle = Colors.primaryLine;
                    if (cell.WrongValue) {
                        ctx.fillStyle = Colors.wrongValue;
                    }
                    else if (cell.Hint) {
                        ctx.fillStyle = Colors.hint;
                    }
                    ctx.fillText(cell.Value, ((1000 / gridSize.current) * y) + 30, ((1000 / gridSize.current) * x) + 90);
                }
                else if (cell.Notes.length > 0) {
                    for (let index = 0; index < cell.Notes.length; index++) {
                        ctx.font = "20pt Arial";
                        ctx.fillStyle = Colors.primaryLine;
                        const noteValueString = cell.Notes[index];
                        const noteValue = parseInt(noteValueString);
                        ctx.fillText(noteValueString, ((1000 / gridSize.current) * y) + (Math.floor((noteValue - 1) % 3) * 30) + 20,
                            ((1000 / gridSize.current) * x) + (Math.floor((noteValue - 1) / 3) * 30) + 35);
                    }

                }
            }
        }
    }

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            clearGrid();
            sudoku.current = new Sudoku();
            sudoku.current.generate(9, 1, true);
            drawGrid();
            reset();
        }
    }, [])

    const newSudoku = () => {
        if (initialized.current) {
            clearGrid();
            sudoku.current.generate(9, 1, true);
            drawGrid();
            reset();
        }
    }

    const solveSudoku = () => {
        const boardCopy: ISudokuCell[][] = JSON.parse(JSON.stringify(sudoku.current.board));
        for (let x = 0; x < boardCopy.length; x++) {
            for (let y = 0; y < boardCopy[x].length; y++) {
                if (boardCopy[x][y].WrongValue) {
                    boardCopy[x][y].Value = "0";
                }
            }
        }

        let solvedBoard = sudoku.current.solveSudokuBoard(boardCopy);
        if (solvedBoard) {
            for (let index = 0; index < solvedBoard.length; index++) {
                const solvedCell = solvedBoard[index];
                const cell = sudoku.current.board[solvedCell.x][solvedCell.y];
                clearGrid();
                cell.WrongValue = false;
                cell.Notes = [];
                cell.Value = solvedCell.value.toString();
                cell.Hint = false;
                cell.Constant = true;
                drawGrid();
            }
        }
    }

    const reset = () => {
        const pointsTable: number[][] = [];
        for (let x = 0; x < gridSize.current; x++) {
            pointsTable.push([]);
            for (let y = 0; y < gridSize.current; y++) {
                pointsTable[x].push(0);
            }
        }
    };

    const onMouseDown = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        isSelected.current = true;
        clearGrid();
        const rects = canvasRef.current!.getClientRects();
        const canvasSize = rects[0].height;
        const xPosition = (
            (event as React.MouseEvent<HTMLCanvasElement>).clientX != undefined
                ? (event as React.MouseEvent<HTMLCanvasElement>).clientX
                : (event as React.TouchEvent<HTMLCanvasElement>).touches[0].clientX
        ) - rects[0].left;
        const yPosition = (
            (event as React.MouseEvent<HTMLCanvasElement>).clientY != undefined
                ? (event as React.MouseEvent<HTMLCanvasElement>).clientY
                : (event as React.TouchEvent<HTMLCanvasElement>).touches[0].clientY
        ) - rects[0].top;
        selectedX.current = Math.floor(xPosition / canvasSize * gridSize.current);
        selectedY.current = Math.floor(yPosition / canvasSize * gridSize.current);
        drawGrid();
    }

    const onKeyDown = (event: any) => {
        setKey(event.key);
        if (event.key == "ArrowDown" || event.key == "ArrowUp" || event.key == "ArrowRight" || event.key == "ArrowLeft") {
            event.preventDefault();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown, true);

        return () => {
            window.removeEventListener("keydown", onKeyDown, true);
        }
    }, [onKeyDown])

    const setKey = (key: string) => {
        if (isSelected.current == false) {
            return;
        }
        const cell = sudoku.current.board[selectedY.current][selectedX.current];
        if (key == "Delete") {
            if (cell.Constant == true) {
                return;
            }
            clearGrid();
            cell.WrongValue = false;
            cell.Notes = [];
            cell.Value = "0";
            drawGrid();
        } else if (key == "1" || key == "2" || key == "3" || key == "4" || key == "5" || key == "6" || key == "7" || key == "8" || key == "9") {
            if (cell.Constant == true) {
                return;
            }
            clearGrid();

            cell.Value = key;
            cell.WrongValue = false;
            cell.Notes = [];
            const boardCopy: ISudokuCell[][] = JSON.parse(JSON.stringify(sudoku.current.board));
            for (let x = 0; x < boardCopy.length; x++) {
                for (let y = 0; y < boardCopy[x].length; y++) {
                    if (boardCopy[x][y].WrongValue) {
                        boardCopy[x][y].Value = "0";
                    }
                }
            }
            if (!sudoku.current.checkSudokuBoard(boardCopy, selectedY.current, selectedX.current)) {
                cell.WrongValue = true;
            }
            drawGrid();
        } else if (key == "ArrowDown") {
            if (selectedY.current < (gridSize.current - 1)) {
                selectedY.current = selectedY.current + 1;
                clearGrid();
                drawGrid();
            }
        } else if (key == "ArrowUp") {
            if (selectedY.current > 0) {
                selectedY.current = selectedY.current - 1;
                clearGrid();
                drawGrid();
            }
        } else if (key == "ArrowRight") {
            if (selectedX.current < (gridSize.current - 1)) {
                selectedX.current = selectedX.current + 1;
                clearGrid();
                drawGrid();
            }
        } else if (key == "ArrowLeft") {
            if (selectedX.current > 0) {
                selectedX.current = selectedX.current - 1;
                clearGrid();
                drawGrid();
            }
        }
    }


    const getNumpad = () => {
        const numArray = [];
        for (let index = 1; index <= gridSize.current; index++) {
            numArray.push(index.toString());
        }
        return numArray.map((val: string) => {
            return <div key={val} className="numpad-item" onClick={() => { setKey(val) }} data-value={val}>{val}</div>
        })
    }

    return (
        <React.Fragment>
            <CustomHelmet title={t("helmet.sudokusolver.title")}
                description={t("helmet.sudokusolver.description")}
                keywords={t("helmet.sudokusolver.keywords")}
                ogTitle={t("helmet.sudokusolver.title")}
                ogDescription={t("helmet.sudokusolver.description")}
                ogImage={t("url.helmetImage")}
            />
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-6 canvas-wrapper px-5'>
                        <canvas ref={canvasRef}
                            onMouseDown={onMouseDown}
                            onTouchStart={onMouseDown}
                            className='main-canvas'
                            width={"1000"}
                            height={"1000"}></canvas>
                    </div>
                    <div className='col-sm-6 canvas-wrapper left-bar'>
                        <div className="text-start w-50">
                            <h1>{t("pages.solver.header1")}</h1>
                            <p>{t("pages.solver.p1")}</p>
                        </div>
                        <div>
                            <div className="numpad-wrapper w-50 mt-2">
                                <div id="numpad" className="numpad">
                                    {getNumpad()}
                                </div>
                            </div>
                            <div className='button main-game-button mt-4 w-50' onClick={() => {
                                solveSudoku();
                            }}>{t("pages.solver.header2")}</div>
                            <div className='button main-game-button mt-4 w-50' onClick={() => {
                                newSudoku();
                            }}>{t("pages.solver.header3")}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className='w-75 text-start mt-5 px-5'>
                        <p>{t("pages.solver.p2")}</p>
                        <h3 className="mt-4">{t("pages.solver.header4")}</h3>
                        <ol>
                            <li><strong>{t("pages.solver.p3")}</strong>{t("pages.solver.p4")}</li>
                            <li><strong>{t("pages.solver.p5")}</strong>{t("pages.solver.p6")}</li>
                            <li><strong>{t("pages.solver.p7")}</strong>{t("pages.solver.p8")}</li>
                        </ol>
                        <h3 className="mt-4">{t("pages.solver.header5")}</h3>
                        <ul>
                            <li><strong>{t("pages.solver.p9")}</strong>{t("pages.solver.p10")}</li>
                            <li><strong>{t("pages.solver.p11")}</strong>{t("pages.solver.p12")}</li>
                            <li><strong>{t("pages.solver.p13")}</strong>{t("pages.solver.p14")}</li>
                        </ul>
                        <p>{t("pages.solver.p15")}</p>
                    </div>
                </div>
            </div>
        </React.Fragment >);
}