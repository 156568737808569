import { difficulty, initialBoard, initialEmptyBoard } from "../constants/sudoku";
import { IS, ISudokuCell } from "../types/interfaces";
import { Hardness } from "../types/types";

export class Sudoku {
    board: ISudokuCell[][] = [];
    constructor() {

    }
    generate(gridSize: number, hardness: Hardness, isEmpty?: boolean) {
        const minNumbers = difficulty[hardness];
        const sudokuTable = this.shuffle(this.createSudokuTable(isEmpty ? initialEmptyBoard : initialBoard));
        for (let index = 0; index < sudokuTable.length - minNumbers; index++) {
            const element = sudokuTable[index];
            element.value = 0;
        }
        const stringBoard = this.sudokuTableToString(sudokuTable);
        this.board = this.convertToObject(stringBoard);
        return stringBoard;
    }

    shuffleNumbers(board: IS[]) {
        for (let i = 1; i < 10; i++) {
            let ranNum = Math.floor(Math.random() * 9) + 1;
            this.swapNumbers(i, ranNum, board);
        }
    }

    swapNumbers(n1: number, n2: number, board: IS[]) {
        for (let index = 0; index < board.length; index++) {
            if (board[index].value == n1) {
                board[index].value = n2;
            } else if (board[index].value == n2) {
                board[index].value = n1;
            }
        }
    }

    shuffleRows(board: IS[]) {
        let blockNumber;

        for (let i = 0; i < 9; i++) {
            let ranNum = Math.floor(Math.random() * 3);
            blockNumber = Math.floor(i / 3);
            this.swapRows(i, blockNumber * 3 + ranNum, board);
        }
    }

    swapRows(x1: number, x2: number, board: IS[]) {
        board.forEach((cell) => {
            if (cell.x == x1) {
                cell.x = x2;
            } else if (cell.x == x2) {
                cell.x = x1;
            }
        });
    }

    shuffleCols(board: IS[]) {
        let blockNumber;
        for (let i = 0; i < 9; i++) {
            let ranNum = Math.floor(Math.random() * 3);
            blockNumber = Math.floor(i / 3)
            this.swapCols(i, blockNumber * 3 + ranNum, board);
        }
    }
    swapCols(y1: number, y2: number, board: IS[]) {
        board.forEach((cell) => {
            if (cell.y == y1) {
                cell.y = y2;
            } else if (cell.y == y2) {
                cell.y = y1;
            }
        });
    }

    shuffle3X3Rows(board: IS[]) {
        for (let i = 0; i < 3; i++) {
            let ranNum = Math.floor(Math.random() * 3);
            this.swap3X3Rows(i, ranNum, board);
        }
    }

    swap3X3Rows(r1: number, r2: number, board: IS[]) {
        for (let i = 0; i < 3; i++) {
            this.swapRows(r1 * 3 + i, r2 * 3 + i, board);
        }
    }

    shuffle3X3Cols(board: IS[]) {
        for (let i = 0; i < 3; i++) {
            let ranNum = Math.floor(Math.random() * 3);
            this.swap3X3Cols(i, ranNum, board);
        }
    }

    swap3X3Cols(c1: number, c2: number, board: IS[]) {
        for (let i = 0; i < 3; i++) {
            this.swapCols(c1 * 3 + i, c2 * 3 + i, board);
        }
    }

    solve(board: string) {
        const sudokuTable = this.createSudokuTable(board);
        const solvedTable = this.solveSudoku(sudokuTable);
        return this.sudokuTableToString(solvedTable!);
    }

    solveSudokuBoard(board: ISudokuCell[][]): IS[] | undefined {
        const sudokuTable = this.createSudokuTable(this.sudokuBoardToString(board));
        return this.solveSudoku(sudokuTable);
    }

    solveSudoku(sudokuTable: IS[]): IS[] | undefined {
        sudokuTable = sudokuTable.sort((a, b) => { return a.candidates.length - b.candidates.length });
        const cell = this.findUnassignedLocation(sudokuTable)

        if (!cell) {
            return sudokuTable;
        }

        for (let index = 0; index < cell.candidates.length; index++) {
            const sudokuTableCopy: IS[] = JSON.parse(JSON.stringify(sudokuTable));
            this.assignCellValue(sudokuTableCopy, cell.x, cell.y, cell.candidates[index]);
            const resolvedSudokuTable = this.solveSudoku(sudokuTableCopy);
            if (resolvedSudokuTable) {
                return resolvedSudokuTable;
            }
        }
        return undefined;
    }

    checkSudokuBoard(board: ISudokuCell[][], x: number, y: number) {
        const sudokuTable = this.createSudokuTable(this.sudokuBoardToString(board));
        const cell = sudokuTable.find(cell => cell.x == x && cell.y == y)!;
        if (sudokuTable.filter(item => item.value == cell.value && (item.x == cell.x || item.y == cell.y || item.squareIndex == cell.squareIndex)).length > 1) {
            return undefined;
        }
        return this.solveSudoku(sudokuTable);
    }

    assignCellValue(sudokuTable: IS[], x: number, y: number, value: number) {
        const cell = sudokuTable.find((item) => item.x == x && item.y == y)!;
        cell.value = value;
        sudokuTable.forEach((item) => {
            if (item.x == cell.x || item.y == cell.y || item.squareIndex == cell.squareIndex) {
                item.candidates = item.candidates.filter((candiate) => { return candiate != value });
            }
        });
    }


    createSudokuTable(board: string): IS[] {
        const sudokuTable: IS[] = [];
        const gridSize = Math.sqrt(board.length);
        const smallGridSize = Math.sqrt(gridSize);
        for (let x = 0; x < gridSize; x++) {
            for (let y = 0; y < gridSize; y++) {
                sudokuTable.push({
                    x,
                    y,
                    candidates: [],
                    value: parseInt(board[(x * gridSize) + y]),
                    squareIndex: ((Math.floor(x / smallGridSize)) * smallGridSize) + (Math.floor(y / smallGridSize))
                });
            }
        }

        sudokuTable.forEach((item) => {
            if (item.value == 0) {
                item.candidates = this.findCandidates(sudokuTable.reduce<number[]>((filtered, candidate) => {
                    if (candidate.x == item.x || candidate.y == item.y || candidate.squareIndex == item.squareIndex) {
                        filtered.push(candidate.value);
                    }
                    return filtered;
                }, []
                ));
            }
        });

        return sudokuTable
    }

    findCandidates(values: number[]) {
        const candidates = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        return candidates.filter((candidate) => !values.includes(candidate));
    }

    findUnassignedLocation(sudokuTable: IS[]): IS | undefined {
        for (let index = 0; index < sudokuTable.length; index++) {
            if (sudokuTable[index].value == 0) {
                return sudokuTable[index]
            }
        }
        return undefined;
    }

    sudokuBoardToString(sudokuTable: ISudokuCell[][]) {
        let board = "";
        for (let x = 0; x < sudokuTable.length; x++) {
            for (let y = 0; y < sudokuTable[x].length; y++) {
                board += sudokuTable[x][y].Value;
            }
        }
        return board;
    }

    sudokuTableToString(sudokuTable: IS[]) {
        let board = "";
        const boardLength = Math.sqrt(sudokuTable.length);
        for (let x = 0; x < boardLength; x++) {
            for (let y = 0; y < boardLength; y++) {
                board += sudokuTable.find((item) => item.x == x && item.y == y)!.value;
            }
        }
        return board;
    }

    convertToObject(board: string): ISudokuCell[][] {
        const boardObject: ISudokuCell[][] = [];
        for (let rowIndex = 0; rowIndex < 9; rowIndex++) {
            boardObject.push([]);
            for (let colIndex = 0; colIndex < 9; colIndex++) {
                const index = (rowIndex * 9) + colIndex;
                boardObject[rowIndex].push({
                    Value: board[index],
                    Constant: board[index] != "0",
                    WrongValue: false,
                    Notes: [],
                    Hint: false
                });
            }
        }
        return boardObject;
    }

    shuffle(array: IS[]) {
        this.shuffleNumbers(array);
        this.shuffleRows(array);
        this.shuffleCols(array);
        this.shuffle3X3Rows(array);
        this.shuffle3X3Cols(array);
        let currentIndex = array.length, randomIndex;
        while (currentIndex > 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

}


