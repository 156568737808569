import React from "react";
import { useTranslation } from "react-i18next";
import { CustomHelmet } from "../components/customhelmet";

export function Cookies() {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <CustomHelmet title={t("helmet.cookies.title")}
                description={t("helmet.cookies.description")}
                keywords={t("helmet.cookies.keywords")}
                ogTitle={t("helmet.cookies.title")}
                ogDescription={t("helmet.cookies.description")}
                ogImage={t("url.helmetImage")}
            />
            <h1 className="cc-h1">{t("pages.cookies.title")}</h1>
            <div className="container text-start">
                <p>{t("pages.cookies.p1")}</p>
                <p>{t("pages.cookies.p2")}</p>
                <p>{t("pages.cookies.p3")}</p>
                <h2>{t("pages.cookies.h1")}</h2>
                <p>{t("pages.cookies.p4")}</p>
                <p>{t("pages.cookies.p5")}</p>
                <h3>{t("pages.cookies.h2")}</h3>
                <p>{t("pages.cookies.p6")}</p>
                <h3>{t("pages.cookies.h3")}</h3>
                <p>{t("pages.cookies.p7")}</p>
                <h3>{t("pages.cookies.h4")}</h3>
                <p>{t("pages.cookies.p8")}</p>
                <h3>{t("pages.cookies.h5")}</h3>
                <p>{t("pages.cookies.p9")}</p>
                <h2>{t("pages.cookies.h6")}</h2>
                <p>{t("pages.cookies.p10")}</p>
                <h2>{t("pages.cookies.h7")}</h2>
                <p>{t("pages.cookies.p11")}</p>
                <h2>{t("pages.cookies.h8")}</h2>
                <p>{t("pages.cookies.p12")}</p>
            </div>
        </React.Fragment>);
}