import { Helmet } from "react-helmet";
import { ICustomHelmetProps } from "../../types/interfaces";
import { useTranslation } from "react-i18next";

export function CustomHelmet(props: ICustomHelmetProps) {
    const { t } = useTranslation();
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description} />
            <meta name="keywords" content={props.keywords} />
            <meta property="og:title" content={props.ogTitle} />
            <meta property="og:description" content={props.ogDescription} />
            <meta property="og:image" content={props.ogImage || t("url.helmetImage")} />
        </Helmet>
    )
}